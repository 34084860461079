var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "development-themes"
  }, [_c('b-card', [_c('h5', [_vm._v("Fields")]), _c('pre', [_vm._v("      " + _vm._s(_vm.fields) + "\n    ")]), _c('h5', [_vm._v("Feedback")]), _c('pre', [_vm._v("      " + _vm._s(_vm.feedback) + "\n    ")]), _c('h5', [_vm._v("Flattened")]), _c('pre', [_vm._v("      " + _vm._s(_vm.flattenedRules) + "\n    ")]), _c('vs-form', {
    attrs: {
      "errors": _vm.feedback
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fields.name,
      expression: "fields.name"
    }],
    domProps: {
      "value": _vm.fields.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fields, "name", $event.target.value);
      }
    }
  })]), _c('vs-form-group', {
    attrs: {
      "field": "test"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fields.test,
      expression: "fields.test"
    }],
    domProps: {
      "value": _vm.fields.test
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fields, "test", $event.target.value);
      }
    }
  })]), _c('vs-form-group', {
    attrs: {
      "field": "sample.age"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fields.sample.age,
      expression: "fields.sample.age"
    }],
    domProps: {
      "value": _vm.fields.sample.age
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fields.sample, "age", $event.target.value);
      }
    }
  })]), _c('vs-form-group', {
    attrs: {
      "field": "sample.name"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fields.sample.name,
      expression: "fields.sample.name"
    }],
    domProps: {
      "value": _vm.fields.sample.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fields.sample, "name", $event.target.value);
      }
    }
  })]), _c('vs-form-group', {
    attrs: {
      "field": "sample.person.name"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fields.sample.person.name,
      expression: "fields.sample.person.name"
    }],
    domProps: {
      "value": _vm.fields.sample.person.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fields.sample.person, "name", $event.target.value);
      }
    }
  })])], 1), _c('div', {
    staticClass: "buttons"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Waliduj ")]), _c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.resetFields
    }
  }, [_vm._v(" Wyczyść formularz ")]), _c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.addErrors
    }
  }, [_vm._v(" Symuluj błąd backendowy ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }