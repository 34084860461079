<template>
  <div class="development-themes">
    <b-card>

      <h5>Fields</h5>
      <pre>
        {{ fields }}
      </pre>

      <h5>Feedback</h5>
      <pre>
        {{ feedback }}
      </pre>

      <h5>Flattened</h5>
      <pre>
        {{ flattenedRules }}
      </pre>

      <vs-form
        :errors="feedback"
      >
        <vs-form-group field="name">
          <input v-model="fields.name">
        </vs-form-group>
        <vs-form-group field="test">
          <input v-model="fields.test">
        </vs-form-group>
        <vs-form-group field="sample.age">
          <input v-model="fields.sample.age">
        </vs-form-group>
        <vs-form-group field="sample.name">
          <input v-model="fields.sample.name">
        </vs-form-group>
        <vs-form-group field="sample.person.name">
          <input v-model="fields.sample.person.name">
        </vs-form-group>
      </vs-form>

      <div class="buttons">
        <vs-button vs-variant="primary" @click="validate">
          Waliduj
        </vs-button>

        <vs-button vs-variant="light" @click="resetFields">
          Wyczyść formularz
        </vs-button>

        <vs-button vs-variant="light" @click="addErrors">
          Symuluj błąd backendowy
        </vs-button>
      </div>

    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';

export default {
  name: 'DevelopmentThemes',
  setup() {
    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignServerSideErrors,
      resetFields,
      flattenedRules,
    } = useValidatedForm({
      name: 'required',
      test: 'min:3',
      sample: {
        name: 'required',
        age: 'required',
        person: {
          name: 'min:6',
        },
      },
    });

    const validate = async () => {
      const valid = await areAllFieldsValid();
      if (!valid) return;
      console.log('Wszystkie pola zostały wypełnione prawidłowo');
    };

    const addErrors = () => {
      assignServerSideErrors({
        name: 'Ta nazwa jest zabroniona przez polskie prawo',
        'sample.person.name': 'Ta osoba nie może',
      });
    };

    return {
      fields,
      feedback,
      areAllFieldsValid,
      addErrors,
      resetFields,
      flattenedRules,
      validate,
    };
  },
  components: {
    VsButton, BCard, VsForm, VsFormGroup,
  },
};
</script>

<style lang="sass" scoped>
.buttons
  display: flex
  align-items: center
  gap: 10px
</style>
